import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { QuillModule } from 'ngx-quill';
import { GalleryComponent } from './pages/gallery/gallery.component'
import { AuthInterceptor } from './api/interceptors/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryPipe } from './api/pipes/gallery.pipe';
import { ArticlesComponent } from './pages/articles/articles.component';
import { EditorFeature } from './features/editor/editor.component';
import { GalleryFeature } from './features/gallery/gallery.component';
import { GalleryNewComponent } from './pages/gallery-new/gallery-new.component';
import { GalleryImageComponent } from './pages/gallery-image/gallery-image.component';
import { AuthModule, LoggerService, LogLevel, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { ArticleComponent } from './pages/article/article.component';
import { ArticleFormFeature } from './features/article-form/article-form.component';
import { StoreModule } from '@ngrx/store';
import { articlesReducer } from './state/articles/articles.reducer';
import { ArticleNewComponent } from './pages/article-new/article-new.component';
import { GalleryPickerFeature } from './features/gallery-picker/gallery-picker.component';
import { ChipsInputComponent } from './components/chips-input/chips-input.component';
import { LoaderFeature } from './features/loader/loader.component';
import { SitesComponent } from './pages/sites/sites.component';
import { RazorModelFeature } from './features/razor-model/razor-model.component';
import { GalleryUploaderFeature } from './features/gallery-uploader/gallery-uploader.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportNewComponent } from './pages/report-new/report-new.component';
import { ReportFormFeature } from './features/report-form/report-form.component';
import { ReportComponent } from './pages/report/report.component';
import { reportsReducer } from './state/reports/reports.reducer';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

const oicdConfig = {
  stsServer: 'https://auth.kyberna.cz',
  redirectUrl: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  clientId: 'kybernaWebAdmin',
  scope: 'api.new.kyberna.cz openid profile roles',
  responseType: 'code',
  silentRenew: true,
  silentRenewUrl: `${window.location.origin}/silent-renew.html`,
  // logLevel: LogLevel.Debug,
};

export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig(oicdConfig);
}

@NgModule({
  declarations: [
    AppComponent,
    GalleryComponent,
    LoginComponent,
    GalleryPipe,
    ArticlesComponent,
    EditorFeature,
    GalleryFeature,
    GalleryNewComponent,
    GalleryImageComponent,
    ArticleComponent,
    ArticleFormFeature,
    ArticleNewComponent,
    GalleryPickerFeature,
    ChipsInputComponent,
    LoaderFeature,
    SitesComponent,
    RazorModelFeature,
    GalleryUploaderFeature,
    ReportsComponent,
    ReportNewComponent,
    ReportFormFeature,
    ReportComponent,
    UnauthorizedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    QuillModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule.forRoot(),
    StoreModule.forRoot({
      articles: articlesReducer,
      reports: reportsReducer
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    OidcConfigService,
    LoggerService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true
    },
    GalleryPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
