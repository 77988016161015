import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'chips-input-component',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true
    }
  ]
})
export class ChipsInputComponent implements OnInit, ControlValueAccessor {

  @Output() public chipAdded = new EventEmitter<string>();
  @Input() public valueKey = "";
  public chips: any[] = [];

  public value = "";

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor() { }

  writeValue(chips: any[]): void {
    this.chips = chips;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
  }

  deleteChip(chip: any) {
    this.chips = [...this.chips.filter(c => c != chip)];
    this.onChange(this.chips);
  }

  addChip() {
    let chip: any = {};
    if (this.valueKey) {
      chip[this.valueKey] = this.value;
    } else {
      chip = this.value;
    }

    // reset value
    this.value = "";

    // fire output event
    this.chipAdded.emit(chip);

    this.chips = [...this.chips, chip];
    this.onChange(this.chips);
  }
}
