<div class="page">
    <aside class="aside" *ngIf="!(hideSidebar$ | async)">
        <div class="aside-header"><span>Kyberna web ADMIN</span></div>
        <nav>
            <ul class="navbar">
                <!-- <li><a [routerLink]="['login']" routerLinkActive="active">Přihlášení</a></li> -->
                <li><a [routerLink]="['articles']" routerLinkActive="active">Články</a></li>
                <li><a [routerLink]="['reports']" routerLinkActive="active">Novinky</a></li>
                <li><a [routerLink]="['gallery']" routerLinkActive="active">Galerie</a></li>
                <li><a [routerLink]="['sites']" routerLinkActive="active">Stránky</a></li>
            </ul>
        </nav>

        <div class="aside-footer">
            <ng-container *ngIf="userData$ | async as userData">
                <b (click)="copyToken()">{{ userData.name}}</b>
                {{ userData.role}}
            </ng-container>

            <button class="primary small" (click)="logout()">Odhlásit se</button>
        </div>
    </aside>
    <main>
        <loader-feature class="loader-feature"></loader-feature>
        <router-outlet></router-outlet>
    </main>
</div>