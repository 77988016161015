import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Photo } from 'src/app/api/models/photo.model';
import { GalleryService } from 'src/app/api/services/gallery.service';
import { LoaderService } from 'src/app/api/services/loader.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(
    private _router: Router
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  navigateNew() {
    this._router.navigateByUrl('gallery/new');
  }

  navigateImage(image: Photo) {
    this._router.navigateByUrl(`gallery/${image.id}`);
  }

}
