import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { RazorModel } from 'src/app/api/models/razor-model';
import { LoaderService } from 'src/app/api/services/loader.service';
import { RazorfieldsService as RazorFieldsService } from 'src/app/api/services/razor-fields.service';

@Component({
  selector: 'razor-model-feature',
  templateUrl: './razor-model.component.html',
  styleUrls: ['./razor-model.component.scss']
})
export class RazorModelFeature implements OnInit {

  @Input() model: RazorModel | undefined;

  editedValue: any | undefined;

  stringify = JSON.stringify;

  constructor(
    private _razorFields: RazorFieldsService,
    private _loader: LoaderService) {
  }

  ngOnInit(): void {
    this.editedValue = this.model?.value;
  }

  setValue(e: any) {
    try {
      this.editedValue = JSON.parse(e?.target?.value);
    } catch (_) {
      this.editedValue = undefined;
    }
  }

  save() {
    if (this.editedValue == undefined || this.model == undefined) return;

    this._loader.setLoading(true);
    this._razorFields.putModel(this.model.type, this.editedValue).pipe(first()).subscribe(_ => {
      this._loader.setLoading(false);
    });
  }

  reset() {
    this._loader.setLoading(true);
    this._razorFields.resetModel(this.model?.type ?? "").pipe(first()).subscribe(_ => {
      this._loader.setLoading(false);
    });
  }
}
