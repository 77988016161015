<ul class="dataview gallery">
    <li *ngFor="let galleryObject of galleryObjects$ | async" class="picture">
        <img [src]="galleryObject | gallery" (click)="imageSelected.emit(galleryObject)" />
        <!-- <img [src]="galleryObject | gallery" /> -->
        <span>{{galleryObject.metadata?.alt}}</span>

        <div class="picture-actions" *ngIf="!hideActions">
            <button class="icon" (click)="imageSelected.emit(galleryObject)"><img
                    src="assets/icons/edit.svg" /></button>

            <button class="icon" (click)="delete(galleryObject)"><img src="assets/icons/delete.svg"></button>
        </div>
    </li>
</ul>