<quill-editor (onEditorCreated)="editorCreated($event)" [modules]="modules" [customModules]="customModules"></quill-editor>

<div class="overlay" *ngIf="imagePopup">
    <div class="overlay-inner">
        <div class="overlay-inner-header">
            <button class="primary" (click)='imagePopup = false'>X</button>
        </div>
        <div class="overlay-inner-body">
            <gallery-uploader-feature (uploaded)="imageSelected($event)"></gallery-uploader-feature>
            <hr>
            <gallery-feature (imageSelected)="imageSelected($event)" [hideActions]='true'></gallery-feature>
        </div>
    </div>
</div>