import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINT } from 'src/consts';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private _http: HttpClient
  ) { }

  getReports() {
    return this._http
      .get<Report[]>(`${API_ENDPOINT}/api/reports`);
  }

  setReportVisible(id: string, visible: boolean) {
    return this._http
      .post(`${API_ENDPOINT}/api/reports/${id}/visible`, visible);
  }

  createReport(report: Report) {
    const { id, ...payload } = { ...report };

    return this._http
      .post<Report>(`${API_ENDPOINT}/api/reports`, payload);
  }

  updateReport(id: string, report: Report) {
    return this._http
      .put<Report>(`${API_ENDPOINT}/api/reports/${id}`, report);
  }

  deleteReport(id: string) {
    return this._http
      .delete(`${API_ENDPOINT}/api/reports/${id}`);
  }
}
