<form *ngIf="articleForm != undefined" class="form" [formGroup]="articleForm" (ngSubmit)="onSubmit()">
    <label for="title">Titulek</label>
    <input placeholder="Cisco zvítězilo." type="text" class="form-control" formControlName="title"
        (input)="regenerateMetaTitle()">

    <label for="metaTitle">Meta titulek</label>
    <div class="form-control form-control-grid">
        <input placeholder="cisco-zvitezilo" type="text" formControlName="metaTitle"
            [attr.generated]="generateMetaTitle" />
        <div class="form-control-grid-checkbox">
            <label class="checkbox">
                <input type="checkbox" (input)="setGenerateMetaTitle($event)" [checked]="generateMetaTitle" />
                <div class="checkbox-inner"></div>
            </label>

            <span>Generovat automaticky</span>
        </div>
    </div>

    <label for="author">Autor</label>
    <input placeholder="Marián Trpkoš" type="text" class="form-control" formControlName="author">

    <label for="image">Obrázek</label>
    <gallery-picker-feature class="form-control" formControlName="image"></gallery-picker-feature>

    <label for="carouselImage">Obrázek carouselu</label>
    <gallery-picker-feature class="form-control" formControlName="carouselImage"></gallery-picker-feature>

    <label for="publish">Datum</label>
    <input type="datetime-local" class="form-control" formControlName="publish">

    <label for="urls">Odkazy</label>
    <chips-input-component class="form-control" formControlName="urls" valueKey="url"></chips-input-component>

    <label for="tags">Tagy</label>
    <div class="form-control-grid">
        <chips-input-component class="form-control" formControlName="tags" (chipAdded)="tagAdded($event)">
        </chips-input-component>
        <div class="form-control-grid-checkbox">
            <label class="checkbox">
                <input type="checkbox" (input)="setAddToCarousel($event)" [checked]="addToCarousel" />
                <div class="checkbox-inner"></div>
            </label>

            <span>Přidat do carouselu</span>
        </div>
    </div>

    <label for="perex">Perex</label>
    <input placeholder="Přiběh o tom jak..." type="text" class="form-control" formControlName="perex">

    <div class="form-editor">
        <label for="content">Obsah</label>
        <editor-feature [content]="article?.content || ''" #editor></editor-feature>
    </div>

    <button class="primary" type="submit" [disabled]="!articleForm.valid">Uložit článek</button>

</form>