import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { GalleryService } from 'src/app/api/services/gallery.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { first, take } from "rxjs/operators";
import { PhotoMetadata } from 'src/app/api/models/photo-metadata.model';
import { Photo } from 'src/app/api/models/photo.model';

@Component({
  selector: 'app-gallery-image',
  templateUrl: './gallery-image.component.html',
  styleUrls: ['./gallery-image.component.scss']
})
export class GalleryImageComponent implements OnInit {

  // public TYPES = Object.keys({});

  // public galleryObjects$: Observable<Photo[]> | undefined;
  public id: string = "";

  public model: Photo | undefined;

  constructor(
    private _gallery: GalleryService,
    private _route: ActivatedRoute,
    private _loader: LoaderService
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.id = params['id'];
      this.fetchImageDetail();
    });
  }

  fetchImageDetail() {
    this._gallery.getPhoto(this.id).pipe(first()).subscribe(photo => {
      this.model = photo;
    });
    // this.galleryObjects$ = this._gallery.getGalleryObjectDetail(this.id);
  }



  onSubmit(f: NgForm) {
    if (this.model?.metadata == undefined) return;

    this._loader.setLoading(true);
    this._gallery.putPhotoMeta(this.id, this.model?.metadata).pipe(first()).subscribe(
      _ => this._loader.setLoading(false)
    );
  }

}
