import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Article } from 'src/app/api/models/article.model';
import { ArticleService } from 'src/app/api/services/article.service';
import { AppState } from 'src/app/state/app.state';
import { updateArticle } from 'src/app/state/articles/articles.actions';
import { selectEntity } from 'src/app/state/articles/articles.selectors';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  public id: string = "";
  public article$: Observable<Article | undefined>;

  constructor(
    private _store: Store<AppState>,
    private _articleService: ArticleService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.article$ = EMPTY;
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.id = params['id'];
      this.article$ = this._store.pipe(select(selectEntity(this.id))).pipe(tap((a) => {
        if (a == undefined) this.goBack();
      }));
    });
  }

  onSubmit(article: Article) {
    console.log("edited article", article);

    this._articleService.updateArticle(article.id, article)
    .pipe(first())
    .subscribe(_ => {
      this._store.dispatch(updateArticle({
        id: article.id,
        article
      }));
    });

    this._router.navigateByUrl('articles');
  }

  goBack() {
    this._router.navigateByUrl('articles');
  }
}
