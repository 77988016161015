<!-- <p>gallery-image works!</p> -->

<ng-container *ngIf="model != undefined">
    <img [src]="model | gallery" />

    <!-- <div *ngIf="galleryObjects$ | async as galleryObjects">
    <ul>
        <li *ngFor="let type of TYPES | keyvalue">
            {{type.value}}
            <div *ngIf="galleryObjects.find(generateTypeFinder(type.key)) as go; else generate">
                {{go.key}}
                <button class="primary" (click)="deleteGalleryImageType(type.value)">Smazat</button>
            </div>
            <ng-template #generate>
                <button class="primary" (click)="generateGalleryImageType(type.key)">Vygenerovat</button>
            </ng-template>
        </li>
    </ul>
</div> -->

    <form #metaForm="ngForm" (ngSubmit)="onSubmit(metaForm)">
        <input placeholder="Alternativní text" [(ngModel)]="model.metadata" name="alt">
        <button class="secondary">Upravit</button>
    </form>
</ng-container>