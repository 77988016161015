import { Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Article } from 'src/app/api/models/article.model';
import { EditorFeature } from '../editor/editor.component';

@Component({
  selector: 'article-form-feature',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.scss']
})
export class ArticleFormFeature implements OnInit {

  @Input() article: Article | undefined;
  @Output() articleSubmit = new EventEmitter<Article>();

  @ViewChild(EditorFeature) editor: EditorFeature | undefined;

  public articleForm: FormGroup | undefined;

  public generateMetaTitle: boolean = false;
  public addToCarousel: boolean = false;

  constructor() {
  }

  ngOnInit(): void {

    this.articleForm = new FormGroup({
      title: new FormControl(this.article?.title, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      metaTitle: new FormControl(this.article?.metaTitle, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
      author: new FormControl(this.article?.author, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20)
      ]),
      perex: new FormControl(this.article?.perex, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(120)
      ]),
      image: new FormControl(this.article?.image, [
        Validators.minLength(1),
        Validators.maxLength(200)
      ]),
      carouselImage: new FormControl(this.article?.carouselImage, [
        Validators.maxLength(200)
      ]),
      publish: new FormControl(this.article?.publish, [
        Validators.required
      ]),
      urls: new FormControl(this.article?.urls),
      tags: new FormControl(this.article?.tags)
    });

    // check if normalized
    if (this.article?.metaTitle == this.normalizeMetaTitle(this.article?.title ?? '')) {
      this.generateMetaTitle = true;
    }

    this.addToCarousel = this.article?.tags?.includes('carousel') ?? false;
  }

  onSubmit() {
    const ops = this.editor?.getContents();
    let content = ops == null ? "" : JSON.stringify(ops);

    if (this.articleForm?.valid) {
      this.articleSubmit.emit({
        ...this.article,
        ...this.articleForm?.value,
        content // ASSIGN EDITOR CONTENT
      } as Article);
    }
  }

  setGenerateMetaTitle(e: any) {
    this.generateMetaTitle = e?.target?.checked ?? false;
    this.regenerateMetaTitle();
  }

  setAddToCarousel(e: any) {
    this.addToCarousel = e?.target?.checked ?? false;

    const tags: string[] = this.articleForm?.value?.tags ?? [];

    if (this.addToCarousel && !tags.includes('carousel')) {
      this.articleForm?.patchValue({
        tags: [...tags, 'carousel']
      });
    }

    if (!this.addToCarousel && tags.includes('carousel')) {
      this.articleForm?.patchValue({
        tags: [...tags.filter(t => t != 'carousel')]
      });
    }
  }

  tagAdded(tag: string) {
    if (tag == 'carousel') {
      this.addToCarousel = true;
    }
  }

  normalizeMetaTitle(title: string) {
    return title
      .replaceAll('-', '')
      .replaceAll(' ', '-')
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLocaleLowerCase();
  }

  regenerateMetaTitle() {
    if (!this.generateMetaTitle) return;

    const title: string = this.articleForm?.value?.title ?? '';
    const metaTitle = this.normalizeMetaTitle(title);
    // console.log("generated meta title", metaTitle);

    this.articleForm?.patchValue({
      metaTitle
    });
  }
}
