import { createAction, props } from "@ngrx/store";
import { Article } from "../../api/models/article.model";

export const addArticles = createAction(
    '[Articles] Add Articles',
    props<{ articles: Article[] }>()
);

export const updateArticle = createAction(
    '[Articles] Update Article',
    props<{ id: string, article: Article }>()
);

export const deleteArticle = createAction(
    '[Articles] Delete Article',
    props<{ id: string }>()
);