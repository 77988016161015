import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private _oicd: OidcSecurityService,
    private _router: Router
  ) {
  }

  // TODO implement roles
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this._oicd.userData$.pipe(map(data => {
      const canAccess = data?.role?.includes('Webmaster') ?? false;

      if (!canAccess) {
        return this._router.createUrlTree(['/unauthorized']);
      }

      return canAccess;
    }));
  }

}
