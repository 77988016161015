<div class="razor-model">
    <h2>{{model?.type}}</h2>
    <textarea [value]="stringify(model?.value)" (input)="setValue($event)">

    </textarea>


    <div class="razor-model-buttons">
        <button type="button" (click)="save()" [disabled]="editedValue == undefined" class="primary">Uložit</button>
        <button type="button" (click)="reset()" class="secondary">Resetovat</button>
    </div>
</div>