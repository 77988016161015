import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(
    private _oicd: OidcSecurityService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  async logout() {
    // TODO FEATURE should logout on server, but server is broken :(

    await this._oicd.logoffLocal();
    this._router.navigateByUrl("/");
  }
}
