import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'kyberna-web-admin';

  hideSidebar$: Observable<boolean>;
  userData$: Observable<any>;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _oicd: OidcSecurityService
  ) {
    this.userData$ = this._oicd.userData$;

    this.hideSidebar$ = this._router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => _activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data),
      map(data => data?.hideSidebar ?? false),
    )
  }

  async logout() {
    // TODO FEATURE should logout on server, but server is broken :(

    await this._oicd.logoffLocal();
    this._router.navigateByUrl("/");
  }

  copyToken() {
    const token = this._oicd.getToken();
    navigator.clipboard.writeText(token);
  }
}
