import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GalleryService } from 'src/app/api/services/gallery.service';
import { LoaderService } from 'src/app/api/services/loader.service';

@Component({
  selector: 'app-gallery-new',
  templateUrl: './gallery-new.component.html',
  styleUrls: ['./gallery-new.component.scss']
})
export class GalleryNewComponent implements OnInit {

  constructor(
    private _router: Router,
  ) { }

  ngOnInit(): void {
  }

  onUploaded() {
    this._router.navigateByUrl('gallery');
  }
}
