import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Article } from 'src/app/api/models/article.model';
import { ArticleService } from 'src/app/api/services/article.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { AppState } from 'src/app/state/app.state';
import { addArticles, deleteArticle, updateArticle } from 'src/app/state/articles/articles.actions';
import { selectAll, selectArticlesState, selectEntities } from 'src/app/state/articles/articles.selectors';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  public articles$ = this._store.pipe(select(selectAll));

  constructor(
    private readonly _articleService: ArticleService,
    private readonly _router: Router,
    private readonly _store: Store<AppState>,
    private readonly _loader: LoaderService
  ) {
  }

  ngOnInit(): void {
    this._loader.setLoading(true);
    this._articleService.getArticles().pipe(take(1)).subscribe(articles => {
      this._store.dispatch(addArticles({ articles }));
      this._loader.setLoading(false);
    });
  }

  toggleVisible(article: Article) {
    const visible = article.visible;

    this._store.dispatch(updateArticle({
      id: article.id,
      article: { ...article, visible: !visible }
    }));

    const sub = this._articleService.setArticleVisible(article.id, !visible).subscribe(_ => {
      // REFETCH
      // this.articles$ = this._articleService.getArticles();

      sub.unsubscribe();
    }, _ => {
      article.visible = !article.visible
      sub.unsubscribe();
    });
  }

  deleteArticle(article: Article) {
    this._store.dispatch(deleteArticle({ id: article.id }));

    this._articleService.deleteArticle(article.id).subscribe(_ => { }, _ => {
      this._store.dispatch(addArticles({ articles: [article] }));
    });
  }

  navigateArticle(article: Article) {
    this._router.navigateByUrl(`/articles/${article.id}`);
  }

  navigateNew() {
    this._router.navigateByUrl('articles/new');
  }
}
