import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { ArticleNewComponent } from './pages/article-new/article-new.component';
import { ArticleComponent } from './pages/article/article.component';
import { ArticlesComponent } from './pages/articles/articles.component';
import { GalleryImageComponent } from './pages/gallery-image/gallery-image.component';
import { GalleryNewComponent } from './pages/gallery-new/gallery-new.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { LoginComponent } from './pages/login/login.component';
import { ReportNewComponent } from './pages/report-new/report-new.component';
import { ReportComponent } from './pages/report/report.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SitesComponent } from './pages/sites/sites.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'authentication/login-callback',
    redirectTo: 'login',
  },
  {
    path: 'gallery',
    component: GalleryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sites',
    component: SitesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gallery/new',
    component: GalleryNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gallery/:id',
    component: GalleryImageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    data: {
      hideSidebar: true
    }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      hideSidebar: true
    }
  },
  {
    path: 'articles',
    component: ArticlesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'articles/new',
    component: ArticleNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'articles/:id',
    component: ArticleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports/new',
    component: ReportNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports/:id',
    component: ReportComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
