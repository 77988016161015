<div class="gallery-picker">
    <!-- <p>{{image}}</p> -->
    <img [src]="fromKey(image)" *ngIf="image; else picker" (click)="overlayPopup = true" />
    <ng-template #picker>
        <button (click)="overlayPopup = true" type="button" class="primary">Pick from gallery</button>
    </ng-template>

    <div class="overlay" *ngIf="overlayPopup">
        <div class="overlay-inner">
            <div class="overlay-inner-header">
                <button class="primary" (click)='overlayPopup = false'>X</button>
            </div>
            <div class="overlay-inner-body">
                <gallery-uploader-feature (uploaded)="imageSelected($event)"></gallery-uploader-feature>
                <hr>
                <gallery-feature (imageSelected)="imageSelected($event)" [hideActions]='true'></gallery-feature>
            </div>
        </div>
    </div>
</div>