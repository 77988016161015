<form *ngIf="reportForm != undefined" class="form" [formGroup]="reportForm" (ngSubmit)="onSubmit()">
    <label for="title">Titulek</label>
    <input placeholder="Cisco zvítězilo." type="text" class="form-control" formControlName="title" />

    <label for="title">Meta titulek</label>
    <input placeholder="Cisco zvítězilo." type="text" class="form-control" formControlName="metaTitle" />

    <label for="title">Autor</label>
    <input placeholder="Cisco zvítězilo." type="text" class="form-control" formControlName="author" />

    <label for="publish">Datum</label>
    <input type="datetime-local" class="form-control" formControlName="publish">

    <div class="form-editor">
        <label for="content">Obsah</label>
        <editor-feature [content]="report?.content || ''" #editor></editor-feature>
    </div>

    <button class="primary" type="submit" [disabled]="!reportForm.valid">Uložit novinku</button>
</form>