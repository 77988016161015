import { createReducer, on } from "@ngrx/store";
import { addReports, deleteReport, updateReport } from "./reports.actions";
import { reportsAdapter } from "./reports.adapter";

export const initialState = reportsAdapter.getInitialState();

export const reportsReducer = createReducer(
    initialState,
    on(addReports, (state, props) => {
        return reportsAdapter.addMany(props.reports, state);
    }),
    on(updateReport, (state, props) => {
        return reportsAdapter.updateOne({
            id: props.id,
            changes: props.report
        }, state);
    }),
    on(deleteReport, (state, props) => {
        return reportsAdapter.removeOne(props.id, state);
    })
)