import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/api/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model = {
    token: ""
  }

  constructor(
    private _auth: AuthService,
    private _oicd: OidcSecurityService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._oicd.checkAuth().subscribe((auth) => console.log('is authenticated', auth));
  }

  login() {
    this._oicd.authorize();
  }

  // logout() {
  //   this._oicd.logoff();
  // }

  status() {
    this._oicd.checkAuth().pipe(take(1)).subscribe(b => console.log(b));
  }

  onSubmit(f: NgForm) {
    this._auth.setToken(f.value?.token);
  }
}
