import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Article } from 'src/app/api/models/article.model';
import { ArticleService } from 'src/app/api/services/article.service';
import { LoaderService } from 'src/app/api/services/loader.service';

@Component({
  selector: 'app-article-new',
  templateUrl: './article-new.component.html',
  styleUrls: ['./article-new.component.scss']
})
export class ArticleNewComponent implements OnInit {

  public article: Article = {
    id: '',
    title: '',
    metaTitle: '',
    author: '',
    perex: '',
    image: '',
    carouselImage: '',
    visible: false,

    created: new Date().toISOString(),
    edited: new Date().toISOString(),
    publish: new Date().toISOString(),

    urls: [],
    tags: [],

    content: ''
  }

  constructor(
    private _articleService: ArticleService,
    private _router: Router,
    private _loader: LoaderService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(article: Article) {
    this._loader.setLoading(true);
    this._articleService.createArticle(article).pipe(first()).subscribe(_ => {
      this._loader.setLoading(false);
      this._router.navigateByUrl('articles');
    });
  }

}
