import { createReducer, on } from "@ngrx/store";
import { addArticles, deleteArticle, updateArticle } from "./articles.actions";
import { articlesAdapter } from "./articles.adapter";

export const initialState = articlesAdapter.getInitialState();

export const articlesReducer = createReducer(
    initialState,
    on(addArticles, (state, props) => {
        return articlesAdapter.addMany(props.articles, state);
    }),
    on(updateArticle, (state, props) => {
        return articlesAdapter.updateOne({
            id: props.id,
            changes: props.article
        }, state);
    }),
    on(deleteArticle, (state, props) => {
        return articlesAdapter.removeOne(props.id, state);
    })
)