import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINT } from 'src/consts';
import { Article } from '../models/article.model';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private _http: HttpClient) { }

  getArticles() {
    return this._http
      .get<Article[]>(`${API_ENDPOINT}/api/articles`);
  }

  setArticleVisible(id: string, visible: boolean) {
    return this._http
      .post(`${API_ENDPOINT}/api/articles/${id}/visible`, visible);
  }

  updateArticle(id: string, article: Article) {
    return this._http
      .put<Article>(`${API_ENDPOINT}/api/articles/${id}`, article);
  }

  createArticle(article: Article) {
    const { id, ...payload } = { ...article };

    return this._http
      .post<Article>(`${API_ENDPOINT}/api/articles`, payload);
  }

  deleteArticle(id: string) {
    return this._http
      .delete(`${API_ENDPOINT}/api/articles/${id}`);
  }
}
