import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { Report } from 'src/app/api/models/report.model';
import { LoaderService } from 'src/app/api/services/loader.service';
import { ReportService } from 'src/app/api/services/report.service';
import { AppState } from 'src/app/state/app.state';
import { addReports, deleteReport, updateReport } from 'src/app/state/reports/reports.actions';
import { selectAll } from 'src/app/state/reports/reports.selectors';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public reports$ = this._store.pipe(select(selectAll));

  constructor(
    private readonly _reportService: ReportService,
    private readonly _router: Router,
    private readonly _store: Store<AppState>,
    private readonly _loader: LoaderService
  ) {
  }

  ngOnInit(): void {
    this.fetch();
  }

  fetch() {
    this._loader.setLoading(true);
    this._reportService.getReports().pipe(take(1)).subscribe(reports => {
      this._store.dispatch(addReports({ reports }));
      this._loader.setLoading(false);
    });
  }

  navigateNew() {
    this._router.navigateByUrl('reports/new');
  }

  toggleVisible(report: Report) {
    const visible = report.visible;

    this._store.dispatch(updateReport({
      id: report.id,
      report: { ...report, visible: !visible }
    }));

    this._reportService.setReportVisible(report.id, !report.visible).pipe(first()).subscribe(_ => {
      this.fetch();
    });
  }

  navigateReport(report: Report) {
    this._router.navigateByUrl(`reports/${report?.id}`);
  }

  deleteReport(report: Report) {
    this._store.dispatch(deleteReport({ id: report.id }));

    this._reportService.deleteReport(report.id).subscribe(_ => { }, _ => {
      this._store.dispatch(addReports({ reports: [report] }));
    });
  }

}
