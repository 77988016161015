<section class="overview">
    <div class="overview-header">
        <h1>Novinky</h1>
        <button class="primary" (click)="navigateNew()">Přidat novinku</button>
    </div>

    <hr>

    <ul class="dataview rows">
        <li *ngFor="let report of reports$ | async ">
            <span>{{report.title}}</span>
            <span>{{report.author}}</span>
            <button class="icon" (click)="toggleVisible(report)">
                <img [src]="report.visible ? 'assets/icons/visible.svg' : 'assets/icons/hidden.svg'" />
            </button>
            <button class="icon" (click)="navigateReport(report)">
                <img src="assets/icons/edit.svg" />
            </button>
            <button class="icon" (click)="deleteReport(report)">
                <img src="assets/icons/delete.svg" />
            </button>
        </li>
    </ul>
</section>