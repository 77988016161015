import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Photo } from 'src/app/api/models/photo.model';
import { GalleryService } from 'src/app/api/services/gallery.service';
import { LoaderService } from 'src/app/api/services/loader.service';

@Component({
  selector: 'gallery-feature',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryFeature implements OnInit {

  @Output() imageSelected = new EventEmitter<Photo>();
  @Input() hideActions: boolean = false;

  public galleryObjects$: Observable<Photo[]>;

  constructor(
    private _gallery: GalleryService,
    public loader: LoaderService
  ) {
    this.loader.setLoading(true);
    this.galleryObjects$ = this._gallery.getPhotos().pipe(
      tap(() => this.loader.setLoading(false))
    );
  }

  ngOnInit(): void {
  }

  delete(picture: Photo) {
    this._gallery.deletePhoto(picture.id).subscribe(() => {
      // REFETCH
      this.galleryObjects$ = this._gallery.getPhotos();
    });
  }
}
