import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { reportsAdapter } from "./reports.adapter";

export const selectReportsState = createSelector(
    (state: AppState) => state.reports,
    reports => reports
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = reportsAdapter.getSelectors(selectReportsState)

export const selectEntity = (id: string) => createSelector(
    selectEntities,
    entities => entities[id]
);