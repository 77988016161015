import { Component, Input, OnInit } from '@angular/core';
import { QuillToolbarConfig } from 'ngx-quill';
import Quill from 'quill';
import { Ellipsis, Inline, Nbsp } from 'src/app/api/core/editor.core';
import { Photo } from 'src/app/api/models/photo.model';
import { GalleryPipe } from 'src/app/api/pipes/gallery.pipe';

@Component({
  selector: 'editor-feature',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorFeature implements OnInit {

  public readonly modules: any = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], ['link', 'image', 'video'], ['nbsp', 'ellipsis']]
  };

  public readonly customModules: any = [
    {
      implementation: Nbsp,
      path: 'formats/nbsp'
    },
    {
      implementation: Ellipsis,
      path: 'formats/ellipsis'
    }
  ];

  @Input() content: string = "";

  private quill: Quill | undefined;
  public imagePopup: boolean = false;

  constructor(private _galleryPipe: GalleryPipe) { }

  ngOnInit(): void {
  }

  editorCreated(editor: Quill) {
    this.quill = editor;

    let toolbar = editor.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler.bind(this));
    toolbar.addHandler('ellipsis', this.ellipsisHandler.bind(this));
    toolbar.addHandler('nbsp', this.nbspHandler.bind(this));

    try {
      const content = JSON.parse(this.content);
      if (content != undefined && Array.isArray(content.ops)) {
        this.quill.setContents(content);
      }
    } catch (e) {
      console.log("Couldn't load quill content", e);
    }
  }

  imageHandler() {
    this.imagePopup = true;
  }

  ellipsisHandler() {
    const range = this.quill?.getSelection();
    this.quill?.insertText(range?.index ?? 0, String.fromCharCode(0x2026));
    this.quill?.setSelection((range?.index ?? 0) + 1, 0);
  }

  nbspHandler() {
    const range = this.quill?.getSelection();
    this.quill?.insertEmbed(range?.index ?? 0, 'nbsp', '');
    this.quill?.setSelection((range?.index ?? 0) + 1, 0);
  }

  getContents() {
    return this.quill?.getContents();
  }

  imageSelected(image: Photo) {
    this.imagePopup = false;

    if (this.quill == undefined) return;

    const url = this._galleryPipe.transform(image);
    const range = this.quill.getSelection();

    this.quill.insertEmbed(range?.index ?? 0, 'image', url);
  }
}
