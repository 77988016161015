<section class="overview">
    <div class="overview-header">
        <h1>Články</h1>
        <button class="primary" (click)="navigateNew()">Přidat článek</button>
    </div>

    <hr>

    <ul class="dataview rows">
        <li *ngFor="let article of articles$ | async ">
            <span>{{article.title}}</span>
            <span>{{article.author}}</span>
            <button class="icon" (click)="toggleVisible(article)">
                <img [src]="article.visible ? 'assets/icons/visible.svg' : 'assets/icons/hidden.svg'" />
            </button>
            <button class="icon" (click)="navigateArticle(article)">
                <img src="assets/icons/edit.svg" />
            </button>
            <button class="icon" (click)="deleteArticle(article)">
                <img src="assets/icons/delete.svg" />
            </button>
        </li>
    </ul>
</section>