import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RazorModel } from 'src/app/api/models/razor-model';
import { RazorfieldsService } from 'src/app/api/services/razor-fields.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {

  razorModels$: Observable<RazorModel[]>;


  constructor(private _razorFields: RazorfieldsService) {
    this.razorModels$ = this._razorFields.getModels();
  }

  ngOnInit(): void {
  }

}
