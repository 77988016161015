import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from 'src/consts';
import { RazorModel } from '../models/razor-model';

@Injectable({
  providedIn: 'root'
})
export class RazorfieldsService {

  constructor(private _http: HttpClient) { }

  public getModels() {
    return this._http
      .get<RazorModel[]>(`${API_ENDPOINT}/api/RazorFields`)
  }

  public putModel(name: string, value: any) {
    return this._http
      .put(`${API_ENDPOINT}/api/RazorFields/${name}`, value);
  }

  public resetModel(name: string) {
    return this._http.
      post(`${API_ENDPOINT}/api/RazorFields/${name}/reset`, {});
  }
}
