import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _loading: boolean = false;
  get loading(): boolean {
    return this._loading;
  }

  constructor() {

  }

  public setLoading(status: boolean = false) {
    this._loading = status;
  }
}
