import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Photo } from 'src/app/api/models/photo.model';
import { GalleryService } from 'src/app/api/services/gallery.service';
import { LoaderService } from 'src/app/api/services/loader.service';

@Component({
  selector: 'gallery-uploader-feature',
  templateUrl: './gallery-uploader.component.html',
  styleUrls: ['./gallery-uploader.component.scss']
})
export class GalleryUploaderFeature implements OnInit {

  @Output() uploaded = new EventEmitter<Photo>()

  file: File | undefined

  constructor(
    private _gallery: GalleryService,
    private _loader: LoaderService
  ) { }


  // TODO local progress

  ngOnInit(): void {
  }

  onFileChange(event: any) {
    this.file = event?.target?.files[0];
  }

  onSubmit() {
    if (this.file == undefined) return;

    this._loader.setLoading(true);
    this._gallery.createPhoto(this.file).subscribe(photo => {
      this._loader.setLoading(false);
      this.uploaded.emit(photo);
    });
  }
}
