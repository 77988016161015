import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Report } from 'src/app/api/models/report.model';
import { ReportService } from 'src/app/api/services/report.service';
import { AppState } from 'src/app/state/app.state';
import { updateReport } from 'src/app/state/reports/reports.actions';
import { selectEntity } from 'src/app/state/reports/reports.selectors';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  id: string = "";
  report$: Observable<Report | undefined>;

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<AppState>,
    private _router: Router,
    private readonly _reportService: ReportService
  ) {
    this.report$ = EMPTY;
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.id = params['id'];
      this.report$ = this._store.pipe(select(selectEntity(this.id))).pipe(tap((r) => {
        if (r == undefined) this.goBack();
      }));
    });
  }

  onSubmit(report: Report) {
    console.log("modified report", report);

    this._reportService.updateReport(report.id, report)
      .pipe(first())
      .subscribe(_ => {
        this._store.dispatch(updateReport({
          id: report.id,
          report
        }));
      });

    this._router.navigateByUrl('reports')
  }

  goBack() {
    this._router.navigateByUrl('reports');
  }

}
