import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private _auth: AuthService,
        private _oicd: OidcSecurityService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log("token", this._oicd.getToken());

        request = request.clone({
            setHeaders: {
                // Authorization: `Bearer ${this._auth.getToken()}`
                Authorization: `Bearer ${this._oicd.getToken()}`
            }
        });

        return next.handle(request);
    }
}