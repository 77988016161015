import Quill from 'quill';

// ICONS 
const icons = Quill.import('ui/icons');
icons['bold'] = '<img src="assets/editor/bold.svg"/>';
icons['italic'] = '<img src="assets/editor/italic.svg"/>';
icons['underline'] = '<img src="assets/editor/underscore.svg"/>';
icons['strike'] = '<img src="assets/editor/strikethrough.svg"/>';
icons['link'] = '<img src="assets/editor/link.svg"/>';
icons['image'] = '<img src="assets/editor/image.svg"/>';
icons['video'] = '<img src="assets/editor/video.svg"/>';
icons['nbsp'] = '<img src="assets/editor/nbsp.svg"/>';
icons['ellipsis'] = '<img src="assets/editor/ellipsis.svg"/>';

// BLOTS
export const Inline = Quill.import('blots/embed');

// NBSP
export class Nbsp extends Inline {
    static create(value: any) {
        let node = super.create(value);
        node.appendChild(document.createTextNode('_'));
        return node;
    }

    format(name: string, value: any) {
        super.format(name, value);
    }
}

Nbsp.blotName = 'nbsp';
Nbsp.className = 'nbsp';
Nbsp.tagName = 'span';

// ELLIPSIS
export class Ellipsis extends Inline {
    static create(value: any) {
        let node = super.create(value);
        return node;
    }

    format(name: string, value: any) {
        super.format(name, value);
    }
}

Ellipsis.blotName = 'ellipsis';
Ellipsis.className = 'ellipsis';
Ellipsis.tagName = 'span';