import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Report } from 'src/app/api/models/report.model';
import { EditorFeature } from '../editor/editor.component';

@Component({
  selector: 'report-form-feature',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormFeature implements OnInit {

  @Input() report: Report | undefined;
  @Output() reportSubmit = new EventEmitter<Report>();

  @ViewChild(EditorFeature) editor: EditorFeature | undefined;

  public reportForm: FormGroup | undefined;

  constructor() { }

  ngOnInit(): void {
    this.reportForm = new FormGroup({
      title: new FormControl(this.report?.title, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      metaTitle: new FormControl(this.report?.metaTitle, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(120)
      ]),
      author: new FormControl(this.report?.author, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20)
      ]),
      publish: new FormControl(this.report?.publish, [
        Validators.required
      ]),
    });
  }

  onSubmit() {
    const ops = this.editor?.getContents();
    let content = ops == null ? "" : JSON.stringify(ops);

    if (this.reportForm?.valid) {
      this.reportSubmit.emit({
        ...this.report,
        ...this.reportForm?.value,
        content
      } as Report);
    }
  }

}
