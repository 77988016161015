<div class="list-control">
    <input type="text" [(ngModel)]="value" class="form-control">
    <button type="button" class="primary" (click)="addChip()">Přidat</button>

    <ul class="tags">
        <li *ngFor="let chip of chips" class="tag">
            <button (click)="deleteChip(chip)">
                <img src="assets/icons/cross.svg" />
            </button>
            <span *ngIf="valueKey; else valueClear">{{chip[valueKey]}}</span>
            <ng-template #valueClear>
                <span>{{chip}}</span>
            </ng-template>
        </li>
    </ul>
</div>