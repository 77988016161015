import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Photo } from '../models/photo.model';
import { PhotoMetadata } from '../models/photo-metadata.model';
import { API_ENDPOINT } from 'src/consts';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private _http: HttpClient) { }

  public getPhotos(): Observable<Photo[]> {
    return this._http
      .get<Photo[]>(`${API_ENDPOINT}/api/gallery`);
  }

  public getPhoto(id: string): Observable<Photo> {
    return this._http
      .get<Photo>(`${API_ENDPOINT}/api/gallery/${id}`);
  }

  // public getGalleryObjectDetail(id: string): Observable<GalleryObject[]> {
  //   return this._http
  //     .get<GalleryObject[]>(`https://new.kyberna.cz/api/gallery/${id}`);
  // }

  public putPhotoMeta(id: string, meta: PhotoMetadata) {
    return this._http
      .put<PhotoMetadata>(`${API_ENDPOINT}/api/gallery/${id}/meta`, meta);
  }

  public deletePhoto(id: string): Observable<any> {
    return this._http
      .delete(`${API_ENDPOINT}/api/gallery/${id}`);
  }

  // public deleteGalleryObjectType(id: string, type: GalleryObjectType): Observable<any> {
  //   return this._http
  //     .delete(`https://new.kyberna.cz/api/gallery/${id}/${type}`);
  // }

  public createPhoto(file: File): Observable<Photo> {
    const form = new FormData();
    form.append('file', file);

    return this._http
      .post<Photo>(`${API_ENDPOINT}/api/gallery`, form);
  }

  // public generateGalleryImageType(id: string, type: any) {
  //   return this._http
  //     .post(`https://new.kyberna.cz/api/gallery/${id}/generate`, type, {
  //       headers: {
  //         "content-type": "application/json"
  //       }
  //     });
  // }
}
