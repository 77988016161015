import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { articlesAdapter } from "./articles.adapter";

export const selectArticlesState = createSelector(
    (state: AppState) => state.articles,
    articles => articles
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = articlesAdapter.getSelectors(selectArticlesState)

export const selectEntity = (id: string) => createSelector(
    selectEntities,
    entities => entities[id]
);