import { createAction, props } from "@ngrx/store";
import { Report } from "src/app/api/models/report.model";

export const addReports = createAction(
    '[Reports] Add Report',
    props<{ reports: Report[] }>()
);

export const updateReport = createAction(
    '[Reports] Update Report',
    props<{ id: string, report: Report }>()
);

export const deleteReport = createAction(
    '[Reports] Delete Report',
    props<{ id: string }>()
);