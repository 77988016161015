import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/api/services/loader.service';

@Component({
  selector: 'loader-feature',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderFeature implements OnInit {

  constructor(public loader: LoaderService) { }

  ngOnInit(): void {
  }

}
