import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _http: HttpClient) {

  }

  setToken(access: string) {
    localStorage.setItem('id_token', access);
  }

  removeToken() {
    localStorage.removeItem('id_token');
  }

  getToken(): string | null {
    return localStorage.getItem('id_token');
  }
}
