import { Pipe, PipeTransform } from '@angular/core';
import { fromKey } from '../helpers/gallery.helper';
import { Photo } from '../models/photo.model';

@Pipe({ name: 'gallery' })
export class GalleryPipe implements PipeTransform {

    transform(object: Photo): string {
        return fromKey(object.key);
    }
}