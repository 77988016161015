import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromKey } from 'src/app/api/helpers/gallery.helper';
import { Photo } from 'src/app/api/models/photo.model';
import { GalleryPipe } from 'src/app/api/pipes/gallery.pipe';

@Component({
  selector: 'gallery-picker-feature',
  templateUrl: './gallery-picker.component.html',
  styleUrls: ['./gallery-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GalleryPickerFeature),
      multi: true
    }
  ]
})
export class GalleryPickerFeature implements OnInit, ControlValueAccessor {

  public image: string;
  public overlayPopup: boolean = false;

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor() {
    this.image = "";
  }

  writeValue(image: string): void {
    console.log("Image picker writing value...");
    this.image = image;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
  }

  fromKey = fromKey;


  imageSelected(image: Photo) {
    this.overlayPopup = false;

    this.onChange(image.key);
    this.image = image.key;
  }
}
