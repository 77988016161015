import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Report } from 'src/app/api/models/report.model';
import { LoaderService } from 'src/app/api/services/loader.service';
import { ReportService } from 'src/app/api/services/report.service';

@Component({
  selector: 'app-report-new',
  templateUrl: './report-new.component.html',
  styleUrls: ['./report-new.component.scss']
})
export class ReportNewComponent implements OnInit {

  public report: Report = {
    id: '',
    title: '',
    metaTitle: '',

    author: '',
    visible: false,

    created: new Date().toISOString(),
    edited: new Date().toISOString(),
    publish: new Date().toISOString(),

    content: ''
  }

  constructor(
    private readonly _loader: LoaderService,
    private readonly _reportService: ReportService,
    private readonly _router: Router
  ) { }

  ngOnInit(): void {
  }

  onSubmit(report: Report) {
    this._loader.setLoading(true);
    this._reportService.createReport(report).pipe(first()).subscribe(_ => {
      this._loader.setLoading(false);
      this._router.navigateByUrl('reports');
    });
  }

}
